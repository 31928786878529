import axios from 'axios';
import { API_URL } from './constants';

const axiosInstance = axios.create({
    baseURL: `${API_URL}`,
    headers: {
        'Content-Type': 'application/json',
    }
})

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `${token}`;
    }
    return config;
});



axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.message === "Network Error" && error.name === "AxiosError") {
            console.log("Network Error occurred:");
        }

        if(error.status === 402 || error.status === 403) {
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;