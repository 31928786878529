import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';

export default function DefaultLayout(){
    return(
        <>
        
        <div className='mx-auto px-5 font-poppins mb-20'>
            <Header />
            <Outlet />
        </div>
        
        
        </>
    )
}