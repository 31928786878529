import { Outlet } from 'react-router-dom';
import React, {useContext, useEffect, useState, useRef} from "react";


import UserContext from "../../context/UserContext";

import Sidebar from './Sidebar';
import Header from './Header';

export default function DefaultLayout(){
    return(
        <>
        
        <Header />
        <div className='mx-auto px-5 font-poppins mb-20'>
            
            <Outlet />
        </div>
        
        
        </>
    )
}