import React, {lazy, Suspense} from 'react';
import {Route, Routes} from "react-router-dom";

import PageLoadingSpinner from "../components/PageLoadingSpinner";

const Login = lazy(() => import('../pages/auth/Login'))
const Register = lazy(() => import('../pages/auth/Register'))
const Register_Package = lazy(() => import('../pages/auth/Register_Package'))
const RegisterSuccess = lazy(() => import('../pages/auth/RegisterSuccess'))

const ForgotPassword = lazy(() => import('../pages/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/auth/ResetPassword'));

export default function AuthRoutes() {
    return (
        <Suspense fallback={<div
            className='flex items-center justify-center w-full h-screen font-bold text-lg'><PageLoadingSpinner /></div>}>
            <Routes>
                <Route path="/login" element={<Login/>} />
                <Route path="/register" element={<Register/>} />
                <Route path="/register/package" element={<Register_Package />} />
                <Route path="/register/success" element={<RegisterSuccess />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />

            </Routes>
        </Suspense>

    )
}
