import React from 'react';
import ProjectChatMessage from "../../components/Notifications/ProjectChatMessage";
import ProjectCompletedRow from "../../components/Notifications/ProjectCompletedRow";
import ProjectUpdatedRow from "../../components/Notifications/ProjectUpdatedRow";
import ProjectMoreMessages from "../../components/Notifications/ProjectMoreMessages";
import {timeDistanceToNow} from "../../helpers/momentHelper"; // New component

export default function NotificationList({ notifications }) {
    // Group notifications by project_id
    if (!notifications || notifications.length === 0) {
        return <p>No notifications available</p>;
    }



    // Group notifications by project_id
    const groupedNotifications = notifications.reduce((acc, notification) => {
        const projectId = notification.project?.id || 'undefined'; // Default to 'undefined' if project id is missing
        if (!acc[projectId]) {
            acc[projectId] = [];
        }
        acc[projectId].push(notification);
        return acc;
    }, {});



    // Function to render different components based on the message content
    const renderNotificationComponent = (notification) => {
        const { message } = notification;

        // Condition for "Project Completed"
        if (message.startsWith('Project Completed:')) {
            return (
                <ProjectCompletedRow
                    key={notification.id}
                    message={message}
                    sender={notification.sender}
                    project_name={notification.project.name}
                    createdAt={notification.createdAt}
                    isRead={notification.is_read}
                />
            );
        }

        // Condition for "Project Update"
        if (message.startsWith('Project Update:')) {
            return (
                <ProjectUpdatedRow
                    key={notification.id}
                    message={message}
                    projectId={notification.project_id}
                    createdAt={notification.createdAt}
                    isRead={notification.is_read}
                />
            );
        }

        // Default condition for single message (ProjectChatMessage)
        return (
            <ProjectChatMessage
                key={notification.id}
                message={message}
                notification={notification}
                sender={notification.sender}
                project_name={notification.project.name}
                createdAt={notification.createdAt}
                isRead={notification.is_read}
            />
        );
    };

    return (
        <>




            {Object.keys(groupedNotifications).map((projectId) => {
                const projectNotifications = groupedNotifications[projectId];

                // If there are multiple notifications for the same project, display the ProjectMoreMessages component
                if (projectNotifications.length > 1) {
                    return (

                        <ProjectMoreMessages
                            key={projectId}
                            notification={projectNotifications[0]}
                            unreadCount={projectNotifications.length}
                        />
                    )
                        ;
                }

                // If there is only one message, render the appropriate component based on the message content
                return renderNotificationComponent(projectNotifications[0]);
            })}
        </>
    );
}