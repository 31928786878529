import axiosInstance from "../config/axiosInstance";

export const validateToken = async (token) => {
    try{
        const data = {
            token 
        }
        const response = await axiosInstance.post(`/auth/token-validate`, data);
        return response.data.data.isValid;

    } catch(error) {
        console.log(error)
        return false;
    }
}