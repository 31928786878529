import React, {lazy, Suspense} from 'react';

import {Route, Routes} from "react-router-dom";
import DefaultLayout from '../pages/layout/DefaultLayout';
import PrivateRoute from '../components/PrivateRoute';
import PageLoadingSpinner from '../components/PageLoadingSpinner';

const Dashboard = lazy(() => import('../pages/Dashboard'));

//Projects
const AllProjects = lazy(() => import('../pages/projects/AllProjects'));
const SingleProject = lazy(() => import('../pages/projects/SingleProject'));
const CreateProject = lazy(() => import('../pages/projects/CreateProject'));

//Brands
const AllBrands = lazy(() => import('../pages/brands/AllBrands'));
const CreateBrand = lazy(() => import('../pages/brands/CreateBrand'));
const SingleBrand = lazy(() => import('../pages/brands/SingleBrand'));

//Users
const AllUsers = lazy(() => import('../pages/users/AllUsers'));

//Settings
const Settings = lazy(() => import('../pages/settings/Settings'))

//ProfileSettings
const ProfileSettings = lazy(() => import('../pages/settings/AccountSettings'));


export default function MainRoutes() {
    return (
        <Suspense fallback={<div className='w-full h-screen flex justify-center items-center'><PageLoadingSpinner /></div>}>
            <Routes>
                <Route path="/manage/*" element={<DefaultLayout/>}>
                    <Route path="dashboard" element={<PrivateRoute><Dashboard/></PrivateRoute>}/>

                    {/* Project Routes */}
                    <Route path="projects" element={<PrivateRoute><AllProjects/></PrivateRoute>}/>
                    <Route path="project/:project_id" element={<PrivateRoute><SingleProject/></PrivateRoute>}/>
                    <Route path="project/create" element={<PrivateRoute><CreateProject/></PrivateRoute>}/>
                    {/* End of Project Routes */}

                    {/* Brand Routes */}
                    <Route path="brands" element={<PrivateRoute><AllBrands/></PrivateRoute>}/>
                    <Route path="brand/create" element={<PrivateRoute><CreateBrand/></PrivateRoute>}/>
                    <Route path="brand/:brand_id" element={<PrivateRoute><SingleBrand/></PrivateRoute>}/>
                    {/* End of Brand Routes */}

                    {/*User Routes*/}
                    <Route path="users" element={<PrivateRoute><AllUsers/></PrivateRoute>} />
                    {/*End of User Routes*/}

                    {/*Settings Routes*/}
                    <Route path="settings" element={<PrivateRoute><Settings /> </PrivateRoute>} />
                    <Route path="profile/settings" element={<PrivateRoute><ProfileSettings/></PrivateRoute>} />

                    {/*End of Settings Routes*/}
                </Route>
            </Routes>
        </Suspense>
    )
}
