import React, {useContext, useEffect, useState, useRef} from "react";
import {Link, useLocation} from "react-router-dom";
import {BACKEND_URL} from "../../config/constants";

import NotificationIcon from "./NotificationIcon";

import UserContext from "../../context/UserContext";
import axiosInstance from "../../config/axiosInstance";


export default function Header(){
    const { user, logout, getUserData } = useContext(UserContext)
    const [isProfileMenuVisible, setProfileMenuVisibility] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [menu, setMenu] = useState([])
    const [notificationCount, setNotificationCount] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const userDetails = {
        firstname: user ? user.firstname : '',
        lastname: user? user.lastname : '',
        user: user? user : null,
        is_default: user? user.is_default : null
    }

    const getNotifications = async () => {
        try{
            const response = await axiosInstance.get('/notifications');
            setNotifications(response.data.data)
            setNotificationCount(response.data.data.length);
        } catch(error){
            console.log(error);
        }
    }

    useEffect(() => {
        getNotifications();
        const intervalId = setInterval(() => {
            getNotifications();
        }, 3000);

        // Cleanup the interval if the component unmounts
        return () => clearInterval(intervalId);
    }, [])


    useEffect(() => {
        // Update menu based on userDetails.is_default
        if (userDetails.is_default) {
            setMenu([
                { name: 'Dashboard', url: '/manage/dashboard' },
                { name: 'Projects', url: '/manage/projects' },
                { name: 'Brands', url: '/manage/brands' },
                { name: 'Users', url: '/manage/users' },
                { name: 'Settings', url: '/manage/settings' }
            ]);
        } else {
            setMenu([
                { name: 'Dashboard', url: '/manage/dashboard' },
                { name: 'Projects', url: '/manage/projects' },
                { name: 'Brands', url: '/manage/brands' }
            ]);
        }
    }, [userDetails.is_default]);

    useEffect(() => {
        getUserData();
    }, [])



    const profileMenuRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
                closeProfileMenu();
            }
        };
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const showProfileMenu = () => {
        setProfileMenuVisibility(!isProfileMenuVisible);
    };

    const closeProfileMenu = () => {
        setProfileMenuVisibility(false);
    };


    return(
        <>
            <div className='hidden md:flex justify-between items-center z-20'>
                <img src="https://creativeocto.fra1.cdn.digitaloceanspaces.com/web_assets/logo.png" className='w-32'/>
                <div className="flex items-center">
                    <nav className='flex items-center mr-5'>
                        {
                            menu.map((list) => {
                                return (
                                    <Link to={list.url}
                                          className="px-5 py-2 hover:bg-lightYellow hover:rounded-xl hover:cursor-pointer">{list.name}</Link>
                                )
                            })
                        }
                    </nav>

                    <NotificationIcon notificationCount={notificationCount} getNotifications={getNotifications} notifications={notifications} />

                    <div className="relative" ref={profileMenuRef}>
                        <div className="py-1 px-5 hover:cursor-pointer rounded-lg bg-gray-100 border border-gray-200"
                             onClick={showProfileMenu}>Hi {userDetails.firstname}</div>

                        <div className={`${
                            isProfileMenuVisible ? 'absolute' : 'hidden'
                        } end-0 z-10 mt-2 w-56 rounded-md border border-gray-100 bg-white shadow-lg`}>
                            <div className="p-2 text-center">
                                <a href="/manage/profile/settings"
                                   className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                   role="menuitem">
                                    Account Settings
                                </a>
                                <a href="#" onClick={logout}
                                   className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                   role="menuitem">
                                    Sign Out
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex md:hidden justify-between items-center '>
                <img src="https://creativeocto.fra1.cdn.digitaloceanspaces.com/web_assets/logo.png" className='w-32'/>

                <div>
                    <div className="flex">
                        <NotificationIcon/>
                        <span className='text-4xl cursor-pointer' onClick={toggleMenu}>=</span>
                    </div>
                    <div className={`${isOpen ? '' : 'hidden'} w-full bg-white absolute left-0 top-0 h-screen z-30`}>
                        <div className='text-center py-5'>
                            <p onClick={toggleMenu}>close</p>
                        </div>



                        <nav className='flex flex-col text-center text-2xl'>
                            {
                                menu.map((list) => {
                                    return (
                                        <a className='py-5'>{list.name}</a>
                                    )
                                })
                            }

                        </nav>


                        <div className='text-center mt-10'>
                            <p className='text-lg font-bold'>Hi Ihthishaam</p>
                            <p>sign out</p>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}