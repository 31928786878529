import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './App.css';
import * as Sentry from "@sentry/react";

import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

Sentry.init({
    dsn: "https://0d8e3ad08b1e3163b31268ec73942873@o4505055718342656.ingest.us.sentry.io/4507994177798144",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
    
  </React.StrictMode>
);

