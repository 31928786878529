import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthRoutes from './routes/AuthRoutes';
import MainRoutes from './routes/MainRoute';

import UserProvider from './context/UserProvider';

function App() {
  return (
    <>
    
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
    </Routes>

    <UserProvider>
      <ToastContainer />
      
      <AuthRoutes />
      <MainRoutes />
    </UserProvider>
    
    </>
    
  );
}

export default App;
